/* Begin -- This is everything related to section 0 on the this year page*/
span {
    color: #ed6d52;
}
.ty-s0{
    background-color: white;
    justify-content: center;
}

.ty-s0 h1{
    font-family: Impact;
    font-size: 4vw;
    letter-spacing: 4px;
    color: #ed6d52;
}

.ty-s0-layer{
    background-image: url('../images/SectionOneWave.svg');
    position: initial;
    display: initial;
    flex-direction: initial;
    align-items: initial;
    min-height: initial;
}
/* End -- This is everything related to section 0 on the this year page*/

/* Begin -- This is everything related to the call to action on the this year page */
.call-to-signup{
    margin-top: 6vw;
    position: relative;
    text-align: center;
}

.call-to-signup .btn
{
    color: #ed6d52;
    background-color: #004f59;
    border-color: #ed6d52;

    margin-top: 2.5vw;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2vw;
    width: 20vw;
    height: 5vw;
}

.call-to-signup .btn:hover 
{
    background-color: #a0b9be; 
    border-color: #004f59;
}
/* End -- This is everything related to the call to action on the this year page */

/* Begin -- This is everything related to section 1 on the this year page */
.ty-s1{
    background-color: #004f59;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -12vw;
    margin-bottom: -10vw;
}

.ty-s1 p{
    color: white;
    font-size: 20px;
}

.ty-s1 h1{
    color: #ed6d52;
}

.ty-s1-r0{
    margin-bottom: 4vw;
}

.ty-s1-r0 h1{
    text-transform: uppercase;
}

.ty-s1-r1{
    text-align: left;
    margin-top: 2vw;
}

.ty-s1-r1 img{
    width: 100%;
    border: #ed6d52;
    border-style: solid;
}

.ty-s1-r2{
    text-align: right;
    margin-top: 2vw;
}

.ty-s1-r2 img{
    width: 100%;
    border: #ed6d52;
    border-style: solid;
}

.ty-s1-r3{
    margin-top: 2vw; 
}

.ty-s1-r3 .btn{
    color: #ed6d52;
    background-color: #a0b9be;
    border-color: #ed6d52;

    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5vw;
    width: 15vw;
    height: 5vw;
}

.ty-s1-r3 .btn:hover 
{
    background-color: #ffffff; 
    border-color: #a0b9be;
}
/* End -- This is everything related to section 1 on the this year page */

/* Begin -- This is everything related to section 2 on the this year page */
.ty-s2{
    background-color: #a0b9be;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -8vw;
    margin-bottom: -6vw;
}

.ty-s2 p{
    color: black;
    font-size: 20px;
}

.ty-s2 h1{
    color: #ed6d52;
}

.ty-s2-r0{
    margin-bottom: 4vw;
}

.ty-s2-r0 h1{
    text-transform: uppercase;
}

.ty-s2-r1{
    text-align: left;
    margin-top: 2vw;
}

.ty-s2-r1 img{
    width: 100%;
    border: #ed6d52;
    border-style: solid;
}

.ty-s2-r2{
    text-align: right;
    margin-top: 2vw;
}

.ty-s2-r2 img{
    width: 100%;
    border: #ed6d52;
    border-style: solid;
}

.ty-s2-r3{
    margin-top: 2vw; 
}

.ty-s2-r3 .btn{
    color: #ed6d52;
    background-color: #004f59;
    border-color: #ed6d52;

    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5vw;
    width: 15vw;
    height: 5vw;
}

.ty-s2-r3 .btn:hover 
{
    background-color: #ffff; 
    border-color: #004f59;
}
/* End -- This is everything related to section 2 on the this year page */

/* Begin -- This is everything related to section 3 on the this year page */
.ty-s3{
    background-color: #ffffff;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -12vw;
    margin-bottom: 4vw;

}

.ty-s3 p{
    color: black;
    font-size: 20px;
}

.ty-s3 h1{
    color: #ed6d52;
}

.ty-s3-r0{
    margin-bottom: 4vw;
}

.ty-s3-r0 h1{
    text-transform: uppercase;
}

.ty-s3-r1{
    text-align: left;
    margin-top: 2vw;
}

.ty-s3-r1 img{
    width: 100%;
    border: #ed6d52;
    border-style: solid;
}

.ty-s3-r2{
    text-align: right;
    margin-top: 2vw;
}

.ty-s3-r2 img{
    width: 100%;
    border: #ed6d52;
    border-style: solid;
}

/* End -- This is everything related to section 3 on the this year page */

@media (max-width: 1199px) {

    .ty-s0 h1{
        font-family: Impact;
        font-size: 8vw;
        letter-spacing: 4px;
        color: #ed6d52;
    }

    .call-to-signup .btn{
    color: #ed6d52;
    background-color: #004f59;
    border-color: #ed6d52;

    margin-top: 2.5vw;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 4vw;
    width: 45vw;
    height: 10vw;
    }

}

@media (max-width: 991px) {

    .ty-s1-r2{
        text-align: left;
    }

    .ty-s1-r3 .btn{
        margin-top: 5vw;
        font-size: 4vw;
        width: 50vw;
        height: 10vw;
    }

    .ty-s2-r2{
        text-align: left;
    }

    .ty-s2-r3 .btn{
        margin-top: 5vw;
        font-size: 4vw;
        width: 50vw;
        height: 10vw;
    }

    .ty-s3-r2{
        text-align: left;
    }
}