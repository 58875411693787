/* Begin -- This is everything related to the frame of the website */
@keyframes backgroundswap {
    0%   {background-image: url('../images/BannerPromo1.jpg'),url('../images/BannerPromo2.jpg'),url('../images/BannerPromo3.jpg');}
    33%  {background-image: url('../images/BannerPromo2.jpg');}
    66%  {background-image: url('../images/BannerPromo3.jpg');}
    100% {background-image: url('../images/BannerPromo1.jpg');}
}

section {
    position: relative;
    display: flex;
    flex-direction: column;
}

.section-head{
    background-image: url('../images/BannerPromo1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    backface-visibility: hidden;
    align-items: center;

    animation: backgroundswap 10s linear infinite 0s;
    animation-timing-function: ease-in-out;
}

.section-one{
    background-color: #004f59;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: start;
    margin-top: -8vw;
    margin-bottom: -8vw;
}

.section-two{
    background-color: #a0b9be;
    min-height: 100px;    
    padding: 0px 5vw;
    text-align: start;
    margin-top: -5vw;
    margin-bottom: -5vw;
}

.section-three{
    background-color: #ffffff;
    min-height: 100px;    
    padding: 0px 5vw;
    text-align: start;
    margin-top: -8vw;
    margin-bottom: 4vw;
}

.spacer{
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.layer1{
    background-image: url('../images/SectionOneWave.svg');
    position: initial;
    display: initial;
    flex-direction: initial;
    align-items: initial;
    min-height: initial;    
    margin-top: 500px ;
}

.layer2{
    background-image: url('../images/SectionTwoWave.svg');
}

.layer3{
    background-image: url('../images/SectionThreeWave.svg');
}
/* End -- This is everything related to the frame of the website */

/* Begin -- This is everything related to the call to action */
.call-to-action{
    position: absolute;
    text-align: center;

    height: 20vw;
    width: 40vw;

    background-color: rgba(255, 255, 255, 0.50);
    margin-top: 100px;

    border-style: solid;
    border-radius: 5px;
    border-color: black;
}

.call-to-action h1
{
    font-size: 3vw;
    margin-top: 5%;
}

.call-to-action h3
{
    font-weight: bold;
    font-size: 2vw;
}

.call-to-action .btn
{
    color: #ed6d52;
    background-color: #004f59;
    border-color: #a0b9be;
    border-radius: 5;
    margin-top: 2vw;
    margin-right: 2.5vw;
    margin-left: 2.5vw;

    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5vw;
    width: 12vw;
    height: 3.5vw;
}

.call-to-action .btn:hover 
{
    background-color: #a0b9be; 
    border-color: #004f59;
}
/* End -- This is everything related to the call to action */


/* Begin -- This is everything related to section one*/
.section-one-text{
    margin-top: -0.5vw;
}

.section-one-text p{
    color: white;
    font-size: 20px;
}

.section-one-text span{
    color: #ed6d52;
}

.section-one-text h1{
    color: #ed6d52;
}

.section-one-image{
    text-align: end;
}

.section-one-image img{
    width: 100%;
    border: #ed6d52;
    border-style: solid;
}
/* End -- This is everything related to section one*/

/* Begin -- This is everything related to section two*/
.section-two-text{
    margin-top: -0.5vw;
}

.section-two-text p{
    color: black;
    text-align: end;
    font-size: 20px;
}

.section-two-text span{
    color: #ed6d52;
}

.section-two-text h1{
    color: #ed6d52;
    text-align: end;
}

.section-two-image{
    text-align: end;
}

.section-two-image img{
    width: 100%;
    border: #ed6d52;
    border-style: solid;
}
/* End -- This is everything related to section two*/

/* Begin -- This is everything related to section three*/
.section-three-text p{
    color: black;
    font-size: 20px;
}

.section-three-text h1{
    color: #ed6d52;
}

.section-three-text span{
    color: #ed6d52;
}
/* End -- This is everything related to section three*/

@media (max-width: 1199px) {
    .section-one-image{
        text-align: center;
        display: none;
    }

    .section-one-text h1{
        text-align: center;  
    }

    .section-two-image{
        text-align: center;
        display: none;
    }

    .section-two-text p{
        text-align: start;  
    }

    .section-two-text h1{
        text-align: center;  
    }

    .section-three-text h1{
        text-align: center;  
    }
}

@media (max-width: 768px) {

    .call-to-action{
        height: 65%;
        width: 75%;
        margin-top: 50px;
    }

    .call-to-action h1
    {
        font-size: 40px;
        margin-top: 5%;
    }

    .call-to-action h3
    {
        font-size: 25px;
    }

    .call-to-action .btn
    {
        margin-top: 8%;
        margin-right: 5%;
        margin-left: 5%;

        font-size: 4vw;
        width: 60%;
        height: 15%;
    }
    
    .section-one-text {
        margin-top: 4vw;
    }

    .section-two-text {
        margin-top: 4vw;
    }

    .section-three-text {
        margin-top: 4vw;
    }
}