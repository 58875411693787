/* Begin -- This is everything related to section 0 on the this year page*/
.hp-s0{
    background-color: white;
    justify-content: center;
}

.hp-s0 h1{
    font-family: Impact;
    font-size: 4vw;
    letter-spacing: 4px;
    color: #ed6d52;
    text-transform: uppercase;
}

.hp-s0-layer{
    background-image: url('../images/SectionOneWave.svg');
    position: initial;
    display: initial;
    flex-direction: initial;
    align-items: initial;
    min-height: initial;
}
/* End -- This is everything related to section 0 on the this year page*/

/* Begin -- This is everything related to the call to action on the this year page */
.call-to-nothing{
    margin-top: 6vw;
    position: relative;
    text-align: center;
}

.call-to-nothing .btn
{
    color: #ed6d52;
    background-color: #004f59;
    border-color: #ed6d52;

    margin-top: 2.5vw;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2vw;
    width: 20vw;
    height: 5vw;
    visibility: hidden;
}
/* End -- This is everything related to the call to action on the this year page */

/* Begin -- This is everything related to the history cards on the this year page */
.card-history {
    width: 21vw;
    margin: 0 auto;
    float: none;
    margin-bottom: 1vw;
    color:#ed6d52;
    padding: 2px;
    background-color: #ed6d52;
}

/* End -- This is everything related to the history cards on the this year page */

/* Begin -- This is everything related to section 1 on the this year page */
.hp-s1{
    background-color: #004f59;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -12vw;
    margin-bottom: -10vw;
}

.hp-s1 h1{
    color: #ed6d52;
}

.hp-s1-r0{
    margin-bottom: 4vw;
}

.hp-s1-r0 h1{
    text-transform: uppercase;
}

.hp-s1-r1{
    text-align: left;
    margin-top: 2vw;
}
/* End -- This is everything related to section 1 on the this year page */

/* Begin -- This is everything related to section 2 on the this year page */
.hp-s2{
    background-color: #a0b9be;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -8vw;
    margin-bottom: -9vw;
}

.hp-s2 p{
    color: black;
}

.hp-s2 h1{
    color: #ed6d52;
}

.hp-s2-r0{
    margin-bottom: 4vw;
}

.hp-s2-r0 h1{
    text-transform: uppercase;
}

.hp-s2-r0{
    text-align: left;
    margin-top: 2vw;
}
/* End -- This is everything related to section 2 on the this year page */

/* Begin -- This is everything related to section 3 on the this year page */
.hp-s3{
    background-color: #ffffff;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -12vw;
    margin-bottom: 0vw;
}

.hp-s3 p{
    color: black;
}

.hp-s3 h1{
    color: #ed6d52;
}

.hp-s3-r0{
    margin-bottom: 4vw;
    text-align: left;
    margin-top: 2vw;
}

.hp-s3-r0 h1{
    text-transform: uppercase;
}
/* End -- This is everything related to section 3 on the this year page */

@media (max-width: 1199px) {

    .hp-s0 h1{
        font-family: Impact;
        font-size: 8vw;
        letter-spacing: 4px;
        color: #ed6d52;
    }

    .call-to-nothing .btn{
    color: #ed6d52;
    background-color: #004f59;
    border-color: #ed6d52;

    margin-top: 2.5vw;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 4vw;
    width: 45vw;
    height: 10vw;
    }

    .card-history{
        width: 40vw;
        margin-bottom: 2vw;
    }

    .card-history-text{
        font-size: 3vw;
    }
}

@media (max-width: 767px) {
    .card-history{
        width: 60vw;
        margin-bottom: 2vw;
    }

    .card-history-text{
        font-size: 4vw;
    }
}