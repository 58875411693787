@font-face {
    font-family: Impact;
    src: url(../fonts/impact.ttf);
  }

.color-nav {
    background-color: #004f59;
    font-family: Impact;   
    font-size: x-large;
    text-transform: uppercase;
}

.color-nav img {
    margin: 10px;
    margin-bottom: 0px;
    width: 65px;
    height: 59px;
}

.color-nav .logoname {
    font-size: xx-large;
    letter-spacing: 4px;

}

.text-link {
    color: inherit;
    text-decoration: inherit;
}

.text-link:hover
{
    color: inherit;
    text-decoration: inherit;
}


