/* Begin -- This is everything related to section 0 on the this year page*/
.sp-s0{
    background-color: white;
    justify-content: center;
}

.sp-s0 h1{
    font-family: Impact;
    font-size: 4vw;
    letter-spacing: 4px;
    color: #ed6d52;
    text-transform: uppercase;
}

.sp-s0-layer{
    background-image: url('../images/SectionOneWave.svg');
    position: initial;
    display: initial;
    flex-direction: initial;
    align-items: initial;
    min-height: initial;
}
/* End -- This is everything related to section 0 on the this year page*/

/* Begin -- This is everything related to the call to action on the this year page */
.call-to-sponsor{
    margin-top: 6vw;
    position: relative;
    text-align: center;
}

.call-to-sponsor .btn
{
    color: #ed6d52;
    background-color: #004f59;
    border-color: #ed6d52;

    margin-top: 2.5vw;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2vw;
    width: 25vw;
    height: 5vw;
}

.call-to-sponsor .btn:hover 
{
    background-color: #a0b9be; 
    border-color: #004f59;
}
/* End -- This is everything related to the call to action on the this year page */

/* Begin -- This is everything related to cards on the this year page */
.card-sponsor {
    width: 21vw;
    margin: 0 auto;
    float: none;
    margin-bottom: 1vw;
    background-color: #004f59;
    color:#ed6d52;
    border-color: #ed6d52;
}

.card-sponsor .card-img-top{
    width: 100%;
    height: 100%;
}
/* End -- This is everything related to cards on the this year page */

/* Begin -- This is everything related to section 1 on the this year page */
.sp-s1{
    background-color: #004f59;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -12vw;
    margin-bottom: -8vw;
}

.sp-s1 p{
    color: white;
    font-size: 20px;
}

.sp-s1 h1{
    color: #ed6d52;
}

.sp-s1-r0{
    margin-bottom: 4vw;
}

.sp-s1-r0 h1{
    text-transform: uppercase;
}

.sp-s1-r1{
    text-align: left;
}
/* End -- This is everything related to section 1 on the this year page */

/* Begin -- This is everything related to section 2 on the this year page */
.sp-s2{
    background-color: #a0b9be;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -8vw;
    margin-bottom: -6vw;
}

/* End -- This is everything related to section 2 on the this year page */

/* Begin -- This is everything related to section 3 on the this year page */
.sp-s3{
    background-color: #ffffff;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -12vw;
    margin-bottom: 4vw;
}

.sp-s3 p{
    color: black;
}

.sp-s3 h1{
    color: #ed6d52;
}

.sp-s3-r0{
    margin-bottom: 1vw;
}

.sp-s3-r0 h1{
    text-transform: uppercase;
}

.sp-s3-r1{
    text-align: left;
    margin-top: 2vw;
}
/* End -- This is everything related to section 3 on the this year page */

@media (max-width: 1199px) {

    .sp-s0 h1{
        font-family: Impact;
        font-size: 8vw;
        letter-spacing: 4px;
        color: #ed6d52;
    }

    .call-to-sponsor .btn{
    color: #ed6d52;
    background-color: #004f59;
    border-color: #ed6d52;

    margin-top: 2.5vw;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 4vw;
    width: 55vw;
    height: 10vw;
    }

    .card-sponsor{
        width: 40vw;
        margin-bottom: 2vw;
    }
}

@media (max-width: 767px) {
    .card-sponsor{
        width: 60vw;
        margin-bottom: 2vw;
    }
}