.rounded-lg {
    border-radius: 1rem !important;
  }
  .text-small {
    font-size: 0.9rem !important;
  }
  .custom-separator {
    width: 5rem;
    height: 6px;
    border-radius: 1rem;
    background-color:#ed6d52; 
  }
  .text-uppercase {
    letter-spacing: 0.2em;
  }

  .headpiece
  {
    font-family: Impact;
    font-size: 20px;
    color:black;
  }

  .headpiece h1
  {
    font-family: Impact;
    color:#ed6d52;
  }

  .mailbutton
  {
    color: #ed6d52;
    background-color: #004f59;
    border-color: #ed6d52;
    width: 150px;
  }

  .mailbutton:hover 
{
    background-color: #a0b9be; 
    border-color: #004f59;
}

  .bbox
  {
    border: 2px solid #ed6d52;
  }
  