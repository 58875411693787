/* Begin -- This is everything related to section 0 on the this year page*/
.cp-s0{
    background-color: white;
    justify-content: center;
}

.cp-s0 h1{
    font-family: Impact;
    font-size: 4vw;
    letter-spacing: 4px;
    color: #ed6d52;
    text-transform: uppercase;
}

.cp-s0-layer{
    background-image: url('../images/SectionOneWave.svg');
    position: initial;
    display: initial;
    flex-direction: initial;
    align-items: initial;
    min-height: initial;
}
/* End -- This is everything related to section 0 on the this year page*/

/* Begin -- This is everything related to the call to action on the this year page */
.call-to-nothing{
    margin-top: 6vw;
    position: relative;
    text-align: center;
}

.call-to-nothing .btn
{
    color: #ed6d52;
    background-color: #004f59;
    border-color: #ed6d52;

    margin-top: 2.5vw;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2vw;
    width: 20vw;
    height: 5vw;
    visibility: hidden;
}
/* End -- This is everything related to the call to action on the this year page */

/* Begin -- This is everything related to the member cards on the this year page */
.card-member {
    width: 22vw;
    margin: 0 auto;
    float: none;
    margin-bottom: 1vw;
    color:black;
    padding: 2px;
    background-color: #a0b9be;
}
.card-member .card-title{
    color: #ed6d52;
}

.card-member .card-img-top{
    width: 100%;
    height: 100%;
}

.card-text{
    font-size: 1.1vw;
}

.card-member .card-body{
    height: auto;
}
/* End -- This is everything related to the member cards on the this year page */

.form-control.contact-text {
    height: 100px;
    margin-top: 3px;
}

.contact-submit{
    margin-top: 3px;
    background-color: #004f59;
    color: #ed6d52;
    text-transform: uppercase;
    font-weight: bold;
}

.contact-submit:hover{
    margin-top: 3px;
    background-color: #a0b9be;
    color: #ed6d52;
    text-transform: uppercase;
    font-weight: bold;
}

form{
    text-align: center;
}

/* Begin -- This is everything related to section 1 on the this year page */
.cp-s1{
    background-color: #004f59;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -12vw;
    margin-bottom: -10vw;
}

.cp-s1 h1{
    color: #ed6d52;
}

.cp-s1-r0{
    margin-bottom: 4vw;
}

.cp-s1-r0 h1{
    text-transform: uppercase;
}

.cp-s1-r1{
    text-align: left;
    margin-top: 2vw;
}
/* End -- This is everything related to section 1 on the this year page */

/* Begin -- This is everything related to section 2 on the this year page */
.cp-s2{
    background-color: #a0b9be;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -8vw;
    margin-bottom: -9vw;
}

.cp-s2 p{
    color: black;
}

.cp-s2 h1{
    color: #ed6d52;
}

.cp-s2-r0{
    margin-bottom: 4vw;
}

.cp-s2-r0 h1{
    text-transform: uppercase;
    text-align: center;
}

.cp-s2-r1 p{
    text-align: left;
}

.cp-s2-r0{
    text-align: left;
    margin-top: 2vw;
}
/* End -- This is everything related to section 2 on the this year page */

/* Begin -- This is everything related to section 3 on the this year page */
.cp-s3{
    background-color: #ffffff;
    min-height: 100px;
    padding: 0px 5vw;
    text-align: center;
    margin-top: -12vw;
    margin-bottom: 0vw;
}

.cp-s3 p{
    color: black;
}

.cp-s3 h1{
    color: #ed6d52;
}

.cp-s3-r0{
    margin-bottom: 4vw;
}

.cp-s3-r1{
    margin-bottom: 4vw;
    text-align: left;
    margin-top: 2vw;
}

.cp-s3-r0 h1{
    text-transform: uppercase;
}
/* End -- This is everything related to section 3 on the this year page */

@media (max-width: 1199px) {

    .cp-s0 h1{
        font-family: Impact;
        font-size: 8vw;
        letter-spacing: 4px;
        color: #ed6d52;
    }

    .call-to-nothing .btn{
    color: #ed6d52;
    background-color: #004f59;
    border-color: #ed6d52;

    margin-top: 2.5vw;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 4vw;
    width: 45vw;
    height: 10vw;
    }

    .card-member{
        width: 35vw;
        margin-bottom: 2vw;
    }

    .card-member-text{
        font-size: 2vw;
    }

    .card-member .card-body{
        height: 38.5vw;
    }

    .card-text{
        font-size: 2vw;
    }
}

@media (max-width: 767px) {
    .card-member{
        width: 65vw;
        margin-bottom: 2vw;
    }

    .card-text{
        font-size: 4vw;
    }

    .card-member-text{
        font-size: 4vw;
    }

    .card-member .card-body{
        height: auto;
    }
}