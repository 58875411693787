footer {
    background-color: #a0b9be;
    font-family: Impact;   
    text-transform: uppercase;
    border-color: black;
    border-top-style: solid;
    border-top-width: 1px;
    padding: 15px;
}

.foot-icon {
    color: black;
    border-style: solid;
    font-size: larger;
    padding: 3px;
    border-radius: 4px;
    margin-right: 10px;
}

#Twitch:hover {
    color: #6441a5;
}

#Facebook:hover {
    color: #4267B2;
}

#Youtube:hover {
    color: #FF0000;
}

#Instagram:hover {
    color: #E1306C;
}

.foot-link{
    margin-left: 10px;
}

.LeftCol {
    text-align: left;
}

.MiddleCol {
    text-align: center;
    letter-spacing: 3px;
    font-size: larger;
}

.RightCol {
    text-align: right;
}

.Mob{
    display: none;
}

@media (max-width: 768px) {
    .LeftCol {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .RightCol {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .MiddleCol {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .Mob{
        display:inline;
    }

    .Desk{
        display: none;
    }
}

